@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./assets/fonts/Montserrat-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-italic';
  src: local('Montserrat-italic'), url(./assets/fonts/Montserrat-Italic-VariableFont_wght.ttf) format('truetype');
}

@base-size: 16px;
@border-size: 1px;
@border-glow-size: 8px;
@bevel-size: 32px;
@icon-size: @base-size*4;
@capability-icon-size: @base-size*1.5;
@capability-space: 24px;

@hex-core-size: @base-size*10;
@hex-core-margin: (@base-size/2);
@hex-core-f: (1.732*@hex-core-size + 4*@hex-core-margin - 1px);
@hex-core-border: 3px;

@hex-nocore-size: @base-size*8;
@hex-nocore-margin: (@base-size/3);
@hex-nocore-f: (1.732*@hex-nocore-size + 4*@hex-nocore-margin - 1px);
@hex-nocore-border: 4px;

@color-leading: #00E9A5;
@color-basic: #FBE829;
@color-lagging: #FF8C84;

/* @color-obsolescence: #00a7d7;
@color-obsolescence-hover: #00A7D77f;
@color-obsolescence-box-hover: #00a8d7b7;
@color-coverage: #0085b4;
@color-coverage-hover: #0085B47f;
@color-coverage-box-hover: #0085B4b7;
@color-evolvability: #006591;
@color-evolvability-hover: #0065917f;
@color-evolvability-box-hover: #006591b7; */

@color-overall: #18859A;
@color-overall-hover: #5EBAD0;
@color-overall-box-hover: #50D1D4;
@color-obsolescence: #00AAE0;
@color-obsolescence-hover: #00C2DF;
@color-obsolescence-box-hover: #00D0F0;
@color-coverage: #897EBA;
@color-coverage-hover: #8291CA;
@color-coverage-box-hover: #7FA4D5;
@color-evolvability: #005B95;
@color-evolvability-hover: #3983B1;
@color-evolvability-box-hover: #5EA2D2;

@color-text: #FFFFFF;
@color-text-input: #000000;

@color-button: #08596e;
@color-button-hover: #5EBAD0;
@color-button-active: #18859A;
@color-button-disabled: #08596e7f;

@color-border: #18859A;
@color-border-glow: #18859A;

@color-header: #00253d;
@color-sider-header: #19384f;

@color-hint: #00A7D7;

@color-map: #18859A;
@color-map-fill: #003e5370;
@color-pin: #00A7D7;

@color-capability: #003e5370;

@color-progress-bar: #00688a;

@color-hex: #003E53;
@color-hex-hover: #0C667D;
@color-hex-active: #0f829e;

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%) translatey(5px) rotateY(16deg);
    transform-origin: 50% 50% 0;
  }

  100% {
    transform: translateX(0) translatey(5px) rotateY(16deg);
    transform-origin: 50% 50% 0;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%) translatey(5px) rotateY(-16deg);
    transform-origin: 50% 50% 0;
  }

  100% {
    transform: translateX(0) translatey(5px) rotateY(-16deg);
    transform-origin: 50% 50% 0;
  }
}

@keyframes floatLeft {
  0% {
    transform: translatey(5px) rotateY(16deg);
    transform-origin: 50% 50% 0;
  }

  50% {
    transform: translatey(-5px) rotateY(12deg);
    transform-origin: 50% 50% 0;
  }

  100% {
    transform: translatey(5px) rotateY(16deg);
    transform-origin: 50% 50% 0;
  }
}

@keyframes floatRight {
  0% {
    transform: translatey(5px) rotateY(-16deg);
    transform-origin: 50% 50% 0;
  }

  50% {
    transform: translatey(-5px) rotateY(-12deg);
    transform-origin: 50% 50% 0;
  }

  100% {
    transform: translatey(5px) rotateY(-16deg);
    transform-origin: 50% 50% 0;
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0)
  }

  100% {
    transform: scale(0.95);
  }
}

html {
  height: 100vh;
  // background: rgb(0, 25, 43);
  background: linear-gradient(0deg, #19384f 0%, #00192b 100%);
  background-attachment: fixed;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  height: 100%;
}

* {
  color: @color-text;
  text-transform: uppercase;
  font-variant-numeric: tabular-nums;
  transition: color 0.5s ease, background-color 0.5s ease, fill 0.5s ease, stroke 0.5s ease;
}

div {
  display: flex;
  flex: 1;
  gap: @base-size;
  letter-spacing: .1rem;
}

.uom {
  font-size: 75%;
  opacity: 0.75;
}

.btn {
  background-color: @color-button;
  border: none;
  font-size: medium;
  cursor: pointer;
  // padding: 0;
  height: @base-size*4;
  flex: 1;
  border-radius: @border-size*2;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: (@base-size/2);
  font-family: 'Montserrat', sans-serif;

  svg {
    fill: @color-text;
    width: (@icon-size/3);
    height: (@icon-size/3);
    padding-bottom: 5px;
  }
}

.btn:not([disabled]):hover {
  background-color: @color-button-hover;
}

.btn[disabled] {
  background-color: @color-button-disabled;
  opacity: 0.50;
  cursor: unset;

  svg {
    opacity: 0.5;
  }
}

.sider-close-btn {
  flex: 0;
  position: absolute;
  top: -20px;
  z-index: 100;

  svg {
    fill: @color-text;
    width: (@icon-size/3);
    height: (@icon-size/3);
    cursor: pointer;
  }
}

.capex-opex-section {
  flex: 0;

  .capex-opex-box {
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    gap: (@base-size/4);

    .capex-opex-title {
      // padding: 0 @base-size*2;
      opacity: 0.75;
      font-weight: 500;
      font-size: 90%;
    }

    .capex-opex-value {
      gap: 3.2px;
      align-items: center;

      .capex-opex-num {
        font-size: xx-large;
      }
    }
  }
}

.total-core-box {
  // flex: 0;

  .total-core-title {
    opacity: 0.8;
    font-size: 80%;
    font-weight: 500;
    align-items: center;
  }

  .total-core-value {
    font-size: xx-large;
    justify-content: end;
  }
}

.indicator-box {
  flex-direction: column;
  gap: (@base-size/2);

  .indicator-info {
    .indicator-name {
      opacity: 0.8;
      font-size: 80%;
      font-weight: 500;
    }

    .indicator-value {
      justify-content: end;
    }
  }

  .progress-bar-container {
    flex-direction: column;
    gap: (@base-size/10);

    .progress-bar-value {
      .progress-bar-perc {
        justify-content: end;
        gap: (@base-size/5);
      }
    }

    .progress-bar {
      position: relative;

      .progress-bar-track {
        position: absolute;
        // left: 0;
        bottom: (@base-size/16);
        height: (@base-size/6);
        width: 100%;
        border-radius: (@base-size/4);
        background-color: @color-progress-bar;
        opacity: 0.5;
      }

      .progress-bar-range {
        border-radius: (@base-size/4);
        height: (@base-size/3);
        z-index: 100;
      }
    }
  }

  .bar-leading {
    .progress-bar-range {
      background-color: @color-leading;
      box-shadow: 0 0 (@base-size/8) (@base-size/16) @color-leading;
    }

    .progress-bar-perc,
    .progress-bar-perc .uom {
      color: @color-leading;
    }
  }

  .bar-basic {
    .progress-bar-range {
      background-color: @color-basic;
      box-shadow: 0 0 (@base-size/8) (@base-size/16) @color-basic;
    }

    .progress-bar-perc,
    .progress-bar-perc .uom {
      color: @color-basic;
    }
  }

  .bar-lagging {
    .progress-bar-range {
      background-color: @color-lagging;
      box-shadow: 0 0 (@base-size/8) (@base-size/16) @color-lagging;
    }

    .progress-bar-perc,
    .progress-bar-perc .uom {
      color: @color-lagging;
    }
  }
}

.wrapper {
  flex-direction: column;
  gap: @base-size*2;
  padding-bottom: @base-size*3;
  background-image:
    linear-gradient(#fefefe05 1px, transparent 2px, transparent 31px, #fefefe10 33px, transparent 2px),
    linear-gradient(to right, #fefefe05 1px, transparent 2px, transparent 31px, #fefefe10 33px, transparent 2px);
  background-size: @base-size*4 @base-size*4;
  background-attachment: fixed;
  background-repeat: repeat;
  overflow: auto;

  .header {
    flex-basis: @base-size*7;
    flex-grow: 0;
    flex-shrink: 0;
    gap: 0;
    filter: drop-shadow(0 0 @border-glow-size @color-border-glow);
    z-index: 1000;

    .logo-border {
      flex: 0 0 @base-size*18;
      clip-path: polygon(0 0, 100% 0, 100% calc(100% - @bevel-size*1.75), calc(100% - @bevel-size*1.75) 100%, 0 100%);
      z-index: 100;
      background-color: @color-border;

      .mds-logo {
        justify-content: center;
        align-items: center;
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - @bevel-size*1.75 - @border-size), calc(100% - @bevel-size*1.75 - @border-size) calc(100% - @border-size*2), 0 calc(100% - @border-size));
        background-color: @color-header;

        svg {
          height: @base-size*3.5;
          cursor: pointer;
          margin-right: @base-size*2;
          fill: @color-text;

          /* .m7 {
            fill: @color-text !important;
          } */
        }
      }
    }

    .navbar {
      gap: 0;
      flex-direction: column;

      .nav {
        background-color: @color-header;
        z-index: 100;
        padding: (@base-size/8) 0;
        border-bottom: @border-size solid @color-border;

        .breadcrumb {
          display: flex;
          align-items: center;
          // margin: 0 0 0 -(@border-size - 1);
          margin: 0;
          gap: (@base-size/2);

          .li-click {
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }

            svg {
              margin-right: @border-size*5;
            }
          }

          li {
            display: flex;

            a {
              color: @color-text;
              text-decoration: none;
              cursor: pointer;

              &:hover {
                text-decoration: underline;
              }
            }

            svg {
              fill: @color-text;
              width: (@icon-size/3);
              height: (@icon-size/3);
            }
          }

          li+li:before {
            padding: 0 (@base-size/8) 0 0;
            color: @color-border;
            content: "/\00a0";
          }

          li:last-of-type {
            font-weight: 700;
          }
        }
      }

      .driver-filter {
        // display: flex;
        gap: 5px;
        background: @color-border;
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - @bevel-size*1.75), calc(100% - @bevel-size*1.75) 100%, 0 100%);
        overflow: hidden;
        padding-right: (@base-size/8);
        margin-right: @base-size*8;
        margin-left: -(@base-size*3.5 - 6);
        padding-left: @base-size*3.5;
        border-bottom: @border-size solid @color-border;

        .parallelogram {
          margin-left: -(@base-size*4);
          clip-path: polygon(0 @bevel-size*1.75, @bevel-size*1.75 0, 100% calc(100% - @bevel-size*1.75), calc(100% - @bevel-size*1.75) 100%, 0 100%);
          border-bottom: @border-size*4 solid transparent;
          border-top: 0;
          background-color: @color-button;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &:hover {
            background-color: @color-overall-box-hover;
          }

          .parallelogram-content {
            flex: 0;
            gap: (@base-size/2);

            .parallelogram-icon svg {
              fill: @color-text;
              width: (@icon-size/3);
              height: (@icon-size/3);
            }
          }
        }

        .parallelogram.active {
          background-color: @color-overall !important;

          &:hover {
            background-color: @color-overall-hover !important;
          }
        }

        .parallelogram.obsolescence:hover {
          background-color: @color-obsolescence-box-hover !important;
        }

        .parallelogram.obsolescence.active {
          background-color: @color-obsolescence !important;

          &:hover {
            background-color: @color-obsolescence-hover !important;
          }
        }

        .parallelogram.coverage:hover {
          background-color: @color-coverage-box-hover !important;
        }

        .parallelogram.coverage.active {
          background-color: @color-coverage !important;

          &:hover {
            background-color: @color-coverage-hover !important;
          }
        }

        .parallelogram.evolvability:hover {
          background-color: @color-evolvability-box-hover !important;
        }

        .parallelogram.evolvability.active {
          background-color: @color-evolvability !important;

          &:hover {
            background-color: @color-evolvability-hover !important;
          }
        }

        .parallelogram.active.leading,
        .parallelogram.obsolescence.active.leading,
        .parallelogram.coverage.active.leading,
        .parallelogram.evolvability.active.leading {
          border-bottom: @border-size*4 solid @color-leading;
        }

        .parallelogram.active.basic,
        .parallelogram.obsolescence.active.basic,
        .parallelogram.coverage.active.basic,
        .parallelogram.evolvability.active.basic {
          border-bottom: @border-size*4 solid @color-basic;
        }

        .parallelogram.active.lagging,
        .parallelogram.obsolescence.active.lagging,
        .parallelogram.coverage.active.lagging,
        .parallelogram.evolvability.active.lagging {
          border-bottom: @border-size*4 solid @color-lagging;
        }
      }

      .spacer {
        flex: 0 0 @border-size*4;
      }

      .submenu {
        position: absolute;
        top: @base-size*7 - @border-size*4 - @border-size;
        display: none;
        cursor: pointer;
        flex: 0 0;

        .submenu-dropdown {
          gap: 0;
          justify-content: space-evenly;
          background-color: @color-button;
          border: @border-size solid @color-border;
          flex-direction: column;

          .menu-item {
            flex: 0;
            padding: @base-size;
            border-bottom: @border-size solid @color-border;

            &:hover {
              background-color: @color-overall-box-hover;
            }

            .item-icon {
              flex: 0;

              svg {
                width: (@icon-size/3);
                height: (@icon-size/3);

                .hex-icon {
                  fill: @color-text;
                }
              }
            }
          }

          .menu-item.obsolescence:hover {
            background-color: @color-obsolescence-box-hover;
          }

          .menu-item.coverage:hover {
            background-color: @color-coverage-box-hover;
          }

          .menu-item.evolvability:hover {
            background-color: @color-evolvability-box-hover;
          }

          .menu-item.all {
            .item-icon {
              .hex-shape {
                fill: @color-hint;
              }
            }
          }

          .menu-item.leading {
            .item-icon {
              .hex-shape {
                fill: @color-leading;
              }
            }
          }

          .menu-item.basic {
            .item-icon {
              .hex-shape {
                fill: @color-basic;
              }

              .hex-icon.basic-icon {
                transform: scaleY(0.4) translate(25px, 90px);
              }
            }
          }

          .menu-item.lagging {
            .item-icon {
              .hex-shape {
                fill: @color-lagging;
              }
            }
          }
        }
      }
    }
  }
}

.content {
  // flex-direction: row;
  gap: @base-size*2;
  perspective: 3000px;
}

.content.hide {
  opacity: 50%;
  pointer-events: none;
}

.wrapper.login {
  justify-content: center;

  .login-content {
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    gap: 0;
    flex: 0;

    .login-img {
      width: @icon-size*5;
      height: @icon-size*5;
      // gap: 0;
      // justify-content: center;
      opacity: 0.25;
      padding-bottom: @base-size*4;

      svg {
        fill: @color-text;
      }
    }

    .login-form {
      display: flex;
      // flex: 0;
      flex-direction: column;
      gap: @base-size*2;

      .login-input {
        gap: (@base-size/2);
        flex-direction: column;

        p {
          margin: 0;
          text-align: center;
        }

        input {
          border-radius: @border-size*2;
          color: @color-text-input;
          width: @base-size*20;
          // height: unset;
          padding: @base-size;
        }

        .login-error-msg {
          color: red;
        }
      }
    }
  }
}

.wrapper.home {
  // justify-content: center;

  .header {
    .navbar {
      .nav {
        padding: 0;

        .nav-title {
          // display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 300;
          font-size: 125%;
        }
      }

      .driver-filter {
        background: transparent;
        border: 0;
      }
    }
  }
}

.sider {
  flex-basis: @base-size*25;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: @color-border;
  margin: @base-size*2 0;
  transform-origin: 50% 50% 0;

  .sider-border {
    background-color: @color-header;

    .sider-box {
      flex-direction: column;
      gap: 0;

      .sider-header-border {
        flex: 0 0 @base-size*6;
        filter: drop-shadow(0 0 @border-glow-size @color-border-glow);
        flex-direction: column;
        // gap: 0;

        .sider-header {
          background-color: @color-border;

          .sider-name {
            background-color: @color-sider-header;
            // flex: 1;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            font-size: 125%;
            padding: 0 0 0 @border-size*4;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            -ms-word-break: break-all;
            word-break: break-all;
            word-break: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
          }
        }
      }

      .sider-content {
        flex-direction: column;
        padding: @base-size*2 @base-size*3;
        // flex: 1;
        position: relative;

        .sider-footer {
          flex: 0 0 @base-size*4;
          // justify-content: center;

          .footer-hint {
            justify-content: center;
            align-items: center;
            background: linear-gradient(90deg, fade(@color-hint, 0) 0%, fade(@color-hint, 70%) 30%, fade(@color-hint, 70%) 70%, fade(@color-hint, 0) 100%);
            margin: 0 -(@base-size*2);
            // flex: 1;
          }
        }
      }

      .sider-content::-webkit-scrollbar {
        width: (@base-size/4);
        border-radius: (@base-size/4);
      }

      .sider-content::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      .sider-content::-webkit-scrollbar-thumb {
        background-color: @color-progress-bar;
        opacity: 0.5;
        border-radius: (@base-size/4);
      }
    }
  }
}

.sider-left {
  flex-basis: @base-size*40;
  overflow: hidden;
  transform: rotateY(32deg) translateX(-8px);
  animation: floatLeft 20s ease 1s infinite, slideInFromLeft 1s ease 0s 1;
  clip-path: polygon(0 0, calc(100% - @bevel-size) 0, 100% @bevel-size, 100% 100%, 0 100%);

  .sider-border {
    clip-path: polygon(0 @border-size, calc(100% - @bevel-size) @border-size, calc(100% - @border-size) @bevel-size + @border-size, calc(100% - @border-size) calc(100% - @border-size), 0 calc(100% - @border-size));

    .sider-box {
      .sider-header-border {
        .sider-header {
          clip-path: polygon(0 0, calc(100% - @bevel-size) 0, 100% @bevel-size, 100% 100%, calc(@bevel-size*3) 100%, calc(@bevel-size*2) calc(100% - @bevel-size), 0 calc(100% - @bevel-size));

          .sider-name {
            clip-path: polygon(0 0, calc(100% - @bevel-size) 0, 100% @bevel-size, 100% calc(100% - @border-size), calc(@bevel-size*3) calc(100% - @border-size*2), calc(@bevel-size*2) calc(100% - @bevel-size - @border-size), 0 calc(100% - @bevel-size - @border-size*2));
          }
        }
      }
    }
  }

  .sider-content {
    justify-content: space-evenly;

    .sider-close-btn {
      left: @base-size*2;
    }

    .map-sm {
      justify-content: center;
      align-items: center;
      flex: 0;

      svg {
        max-width: @base-size*24;
        max-height: @base-size*14;

        path {
          stroke: @color-map;
          stroke-dasharray: unset;
          stroke-width: @border-size*5;
        }

        path#italy-sm,
        path#spain-sm,
        path#border-sm {
          fill: @color-map;
          fill-opacity: 0.2;
        }
      }
    }

    .info-rows {
      flex-direction: column;
      gap: @base-size*3;
      flex: 0;
      padding: 0 @base-size;

      .info-row {
        // align-items: center;
        // flex: 0;
        gap: @base-size*3;

        .info-content {
          // align-items: stretch;
          gap: 0;

          .info-icon-border {
            justify-content: center;
            align-items: center;
            clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
            // height: @icon-size + @border-size;
            // width: @icon-size + @border-size;
            flex: 0 0 @icon-size + @border-size;
            background-color: @color-border;

            .info-icon {
              justify-content: center;
              align-items: center;
              clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
              height: @icon-size;
              width: @icon-size;
              background-color: @color-sider-header;
              flex-basis: @icon-size;
              flex-grow: 0;
              flex-shrink: 0;

              svg {
                fill: @color-text;
                width: (@icon-size/2);
                height: (@icon-size/2);
                opacity: 0.75;
              }
            }
          }

          .info-box {
            flex-direction: column;
            gap: 0;
            // height: 100%;

            .info-title {
              border-bottom: (@border-size/2) solid @color-border;
              flex: 1 0 ((@icon-size + @border-size)/2) - (@border-size/2);

              div {
                justify-content: center;
                align-items: end;
                padding-bottom: (@base-size/6);
                font-weight: 700;
              }
            }

            .info-value {
              flex: 1 0 ((@icon-size + @border-size)/2);
              font-weight: 300;
              gap: 3.2px;
              align-items: center;
              justify-content: center;

              div {
                // justify-content: center;
                padding-top: (@base-size/6);
                font-size: x-large;
                flex: 0;
              }

              .uom {
                padding-top: (@base-size/4);
              }
            }
          }
        }
      }

      .row-reverse {
        .info-content {
          flex-direction: row-reverse;
        }
      }
    }
  }
}

.sider-right {
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 @bevel-size, @bevel-size 0);
  transform: rotateY(-16deg) translateX(-8px);
  animation: floatRight 20s ease 1s infinite, slideInFromRight 1s ease 0s 1;

  .sider-border {
    border-right: 0;
    border-bottom: @border-size solid @color-border;

    .sider-box {
      .sider-header-border {
        .sider-header {
          padding: @border-size 0 @border-size @border-size;
          clip-path: polygon(100% 0, 100% calc(100% - @bevel-size), calc(100% - @bevel-size*2) calc(100% - @bevel-size), calc(100% - @bevel-size*3) 100%, 0 100%, 0 @bevel-size, @bevel-size 0);
          flex: 0 0 @base-size*6;

          .sider-name {
            clip-path: polygon(100% 0, 100% calc(100% - @bevel-size), calc(100% - @bevel-size*2) calc(100% - @bevel-size), calc(100% - @bevel-size*3) 100%, 0 100%, 0 @bevel-size, @bevel-size 0);
            // justify-content: center;
            padding: 0 @base-size*6 0 @base-size*2;
            text-align: center;
          }
        }
      }

      .sider-content {
        border-left: @border-size solid @color-border;
        // border-right: 0;
        // border-bottom: @border-size solid @color-border;

        .sider-close-btn {
          right: 22px;
        }

        .sider-section {
          flex: 0;
          // justify-content: center;
          margin-top: @base-size*2;
          border-bottom: @border-size solid @color-border;
          padding-bottom: (@base-size/4);

          .section-title {
            // flex: 1;
            justify-content: center;
            // border-bottom: 0;
            // padding: 0 (@base-size/2);
            font-size: large;
          }
        }

        .sider-section:first-of-type,
        .sider-close-btn+.sider-section {
          margin-top: 0;
        }

        .systems {
          gap: @base-size*1.5;
          flex: 0;

          .systems-column {
            flex-direction: column;

            .driver-indicators {
              flex-direction: column;
              gap: @base-size*2;
              padding: @base-size 3.2px;

              /* .driver-info-box {
                flex: 0;
              } */
            }
          }
        }

        .detail-icons {
          flex: 0;
          gap: (@base-size/4);

          .icon-box {
            flex-direction: column;
            align-items: center;
            gap: (@base-size/4);

            svg {
              width: @base-size*3;
              height: @base-size*3;

              .hex-icon {
                fill: @color-text;
                transform: scale(0.8) translate(25px, 25px);
                transform-origin: center;
              }

              .basic-icon {
                transform: scaleY(0.4) translate(25px, 25px);
              }
            }

            .icon-title {
              flex-direction: column;
              flex: 1 0 ((@icon-size + @border-size)/2);
              // gap: 0;
              // height: 100%;

              div {
                // justify-content: center;
                padding-top: (@base-size/6);
                font-size: 80%;
                font-weight: 500;
              }
            }
          }
        }

        .detail-infos {
          flex: 0;
          flex-direction: column;

          .sider-section.no-space {
            margin-top: 0 !important;
          }

          .detail-description {
            // flex: 0;
            text-transform: initial;
            letter-spacing: 0;
          }

          .detail-title {
            padding: 0 (@base-size/2);
            font-size: 80%;
            font-weight: 600;
            color: @color-border;
          }

          p.detail-title {
            text-align: center;
          }

          .initiative-box {
            flex-direction: column;
            gap: (@base-size/2);
            border: @border-size solid @color-border;
            padding: @base-size*2 @base-size*2;
            transform-style: preserve-3d;
            transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);

            .initiative-content {
              flex-direction: column;
              transform: translateZ(20px);

              .initiative-title {
                text-align: center;
                margin: 0;
              }

              .initiative-row {
                justify-content: center;

                .initiative-date {
                  margin: 0;
                  opacity: 0.5;
                  font-size: small;
                  margin-bottom: @base-size*2;
                }

                .detail-info {
                  flex-direction: column;
                  align-items: center;
                  // justify-content: center;
                }
              }
            }
          }

          .detail-info .detail-value {
            gap: 8px;

            .uom {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }
}

.sider-detail {
  position: sticky;
  top: 5%;
  height: calc(100vh - @base-size*7);
  perspective: 3000px;
  flex-basis: @base-size*25;
  flex-grow: 0;
  flex-shrink: 0;

  .sider-border {
    .sider-box {
      .sider-close-btn {
        right: 22px;
        top: 78px;
      }

      .sider-content {
        margin-right: (@base-size/2);
        padding: @base-size*2;
        gap: @base-size*2;
        overflow-y: auto;
      }
    }
  }
}

.map-lg {
  align-items: center;
  justify-content: center;
  animation: 1s ease-in-out 0s 1 zoomIn;

  svg {
    max-height: calc(100vh - @base-size*20);

    .country {
      stroke: @color-map;
      stroke-width: @border-size;
      stroke-opacity: 0.25;
      fill: @color-map-fill;
      fill-opacity: 0.5;

      &:hover {
        fill-opacity: 1;
      }
    }

    #border {
      stroke: @color-map;
      stroke-width: @border-size*2;
      fill: none;
    }

    .pin {
      fill: @color-pin;
      stroke: @color-pin;
    }

    .pin-line {
      stroke: @color-pin;
    }
  }

  #italy,
  #spain {
    fill: @color-map;
    fill-opacity: 0.25;
    stroke-opacity: 1;
  }

  #it:hover .country,
  #es:hover .country {
    fill: @color-map !important;
    fill-opacity: 0.5;
    stroke-opacity: 1;
  }

  #it.selected,
  #es.selected {
    .country {
      fill: @color-map !important;
      fill-opacity: 0.75;
      stroke-opacity: 1;
      stroke-width: @border-size*2;
    }

    .pin {
      fill: @color-text !important;
      stroke: @color-text !important;
    }

    .pin-line {
      stroke: @color-text !important;
    }
  }

  #cologno,
  #elios,
  #palatino,
  #villaviciosa,
  #fuencarral {
    letter-spacing: 0;
  }

  .pinned {
    transform: scale(1.25);
    transform-box: fill-box;
  }

  .pinned-sx {
    transform-origin: bottom left;
  }

  .pinned-dx {
    transform-origin: bottom right;
  }
}

.cap-map {
  animation: zoomIn 1s ease 0s 1;
  transform: scale(0.95);
  flex-direction: column;
  margin-left: @base-size*2;
  gap: @capability-space;

  .cap-center {
    flex: 4;
    gap: @capability-space;

    .cap-med-center {
      flex-direction: column;
      flex: 4;
      gap: @capability-space;

      .cap-super-center {
        gap: @capability-space;
      }
    }
  }

  .capability {
    background-color: @color-capability;
    // padding: 0;
    cursor: pointer;

    .capability-content {
      background-color: @color-capability;

      &:hover {
        background-color: @color-overall-box-hover;
      }

      .cap-name {
        font-size: 105%;
        font-weight: 500;
        text-align: center;
      }

      .cap-info-container {
        // flex: 0;
        align-items: center;

        .cap-info {
          // align-items: stretch;
          // gap: @base-size;

          span {
            display: flex;
            flex-direction: column;
            // justify-items: center;
          }

          p {
            margin: 0;
            // text-align: center;
          }

          .title {
            font-weight: 600;
            font-size: 50%;
            opacity: 0.5;
            display: flex;
            // flex: 0;
            justify-content: center;
          }

          .value {
            justify-content: center;
            align-items: center;
            display: flex;
            flex: 1;

            .indicator-icon {
              svg {
                width: @capability-icon-size;
                height: @capability-icon-size;
              }

              .hex-icon {
                fill: @color-text;
              }
            }

            .indicator-icon.icon-leading {
              .hex-shape {
                fill: @color-leading;
              }
            }

            .indicator-icon.icon-basic {
              .hex-shape {
                fill: @color-basic;
              }

              .hex-icon.basic-icon {
                transform: scaleY(0.4) translate(25px, 90px);
              }
            }

            .indicator-icon.icon-lagging {
              .hex-shape {
                fill: @color-lagging;
              }
            }
          }

          .total-num {
            // align-items: stretch;
            // border-right: @border-size solid #ffffff37;
            // padding-right: @base-size;
            padding: (@base-size/4) + @border-size;

            .value {
              opacity: 0.75;
            }
          }

          .core-box {
            border: @border-size solid #ffffff37;
            padding: (@base-size/4) @base-size;
            // border-radius: 10px;
            // box-shadow: 0 0 8px 1px #18859A;

            .core-num {
              font-weight: 500;

              .value {
                font-size: 150%;
              }
            }

            .core-perc {
              .value {
                gap: (@base-size/4);
              }
            }
          }

          .total-num,
          .core-num,
          .core-perc {
            gap: (@base-size/4);
          }
        }
      }
    }
  }

  .capability-content.active {
    background-color: @color-overall !important;

    &:hover {
      background-color: @color-overall-hover !important;
    }
  }

  .capability-hor-lg {
    position: relative;
    flex: 0.5;
    align-items: stretch;
    overflow: hidden;
    clip-path: polygon(0 50%, @bevel-size 0, calc(100% - @bevel-size) 0, 100% 50%, calc(100% - @bevel-size) 100%, @bevel-size 100%);
    border-top: @border-size*2 solid @color-border;

    .capability-content-hor-lg {
      // clip-path: polygon(0 50%, @bevel-size 0, calc(100% - @bevel-size) 0, 100% 50%, 100% 100%, 0 100%);
      justify-content: center;
      gap: @base-size*6;

      .cap-name {
        align-items: center;
        flex: 0 0 fit-content;
      }

      .cap-info-container {
        flex: 0;
      }
    }
  }

  .capability-hor-md,
  .capability-hor-sm {
    position: relative;
    clip-path: polygon(0 (@bevel-size/2), (@bevel-size/2) 0, 100% 0, 100% calc(100% - (@bevel-size/2)), calc(100% - (@bevel-size/2)) 100%, 0 100%);
    border-top: @border-size*2 solid @color-border;

    .capability-content-hor-md,
    .capability-content-hor-sm {
      justify-content: center;
      flex-direction: column;
      // border-top: @border-size*2 solid @color-border;
      // gap: (@base-size/1);

      .cap-name {
        flex: 0;
        justify-content: center;
        // align-items: end;
      }

      .cap-info-container {
        flex: 0;
        // align-items: start;
        justify-content: center;

        .cap-info {
          flex: 0;
        }
      }
    }
  }

  .capability-vert {
    position: relative;
    // flex: 1;
    // flex-direction: column;
    clip-path: polygon(0 0, calc(100% - @bevel-size) 0, 100% @bevel-size, 100% 100%, @bevel-size 100%, 0 calc(100% - @bevel-size));
    // align-items: stretch;
    overflow: hidden;
    border-left: @border-size*2 solid @color-border;

    .capability-content-vert {
      // flex-direction: row;
      // clip-path: polygon(0 0, calc(100% - @bevel-size) 0, 100% @bevel-size, 100% 100%, @bevel-size 100%, 0 calc(100% - @bevel-size));
      align-items: center;

      .cap-name {
        writing-mode: vertical-rl;
        transform: rotate(-180deg);
        padding-right: (@base-size/2);
        flex: 0;
      }

      .cap-info-container {
        // flex-direction: row;
        justify-content: center;
        flex: 1;

        .cap-info {
          flex-direction: column;
          flex: 0;

          /* .total-num {
            padding: 0;
            padding-bottom: @base-size;
            border: 0;
            border-bottom: @border-size solid #ffffff37;
          } */

          .core-box {
            flex-direction: column;
          }
        }
      }
    }
  }

  .capability-hor-lg::after,
  .capability-hor-md::after,
  .capability-hor-sm::after {
    content: "";
    filter: drop-shadow(0 0 @border-glow-size @color-border-glow);
    width: 100%;
    position: absolute;
    height: 10px;
    background: @color-border;
    margin-top: -10px;
  }

  .capability-vert::after {
    content: "";
    filter: drop-shadow(0 0 @border-glow-size @color-border-glow);
    height: 100%;
    position: absolute;
    width: 10px;
    background: @color-border;
    margin-left: -10px;
  }
}

.cap-map.obsolescence {

  .capability {
    border-color: @color-obsolescence !important;

    .capability-content:hover {
      background-color: @color-obsolescence-box-hover !important;
    }

    .capability-content.active {
      background-color: @color-obsolescence !important;

      &:hover {
        background-color: @color-obsolescence-hover !important;
      }
    }
  }

  .capability::after {
    background-color: @color-obsolescence;
    filter: drop-shadow(0 0 (@base-size/2) @color-obsolescence);
  }
}

.cap-map.coverage {

  .capability {
    border-color: @color-coverage !important;

    .capability-content:hover {
      background-color: @color-coverage-box-hover !important;
    }

    .capability-content.active {
      background-color: @color-coverage !important;

      &:hover {
        background-color: @color-coverage-hover !important;
      }
    }
  }

  .capability::after {
    background-color: @color-coverage;
    filter: drop-shadow(0 0 (@base-size/2) @color-coverage);
  }
}

.cap-map.evolvability {

  .capability {
    border-color: @color-evolvability !important;

    .capability-content:hover {
      background-color: @color-evolvability-box-hover !important;
    }

    .capability-content.active {
      background-color: @color-evolvability !important;

      &:hover {
        background-color: @color-evolvability-hover !important;
      }
    }
  }

  .capability::after {
    background-color: @color-evolvability;
    filter: drop-shadow(0 0 (@base-size/2) @color-evolvability);
  }
}

.detail-banner {
  padding: @base-size*13 @base-size*2 @base-size*2 @base-size*2;
  border-bottom: @border-size solid @color-border;
  filter: drop-shadow(0 0 (@base-size/2) @color-border);
  background-color: @color-header;
  flex: 0 0 @base-size*6;
  margin-top: -(@base-size*13);

  .banner-box {
    flex-direction: column;
    gap: 32px;

    .banner-row {
      flex: 0 0 @base-size*4;
      gap: @base-size*6;

      .banner-info-box {
        align-items: center;
        justify-content: space-evenly;
        flex: 0 0 @base-size*12;

        .banner-icon {
          flex: 0;
          justify-content: center;

          svg {
            width: (@icon-size/2);
            height: (@icon-size/2);
            fill: @color-text;
          }
        }

        .total-core-box {
          // border-bottom: 0;

          .total-core-title {
            // align-items: center;
            opacity: unset;
            font-size: initial;
            font-weight: initial;
          }

          .total-core-value {
            align-items: center;
          }
        }
      }

      .indicator-box {
        .banner-indicator {
          .banner-icon {
            flex: 0;

            svg {
              width: (@icon-size/3);
              height: (@icon-size/3);
              fill: @color-text;

              .basic-icon {
                transform: scaleY(0.2) translate(10px, 90px);
              }
            }
          }
        }
      }
    }

    .banner-row:first-of-type {
      padding-bottom: @base-size*2;
    }
  }

  .indicator-info {
    flex: 0;
    gap: (@base-size/2);
    width: fit-content;

    .indicator-name {
      opacity: unset !important;
      font-size: initial !important;
    }
  }

  .bar-leading,
  .bar-basic,
  .bar-lagging {
    flex: 0;
  }
}

.detail-banner.hide {
  opacity: 50%;
  pointer-events: none;
}

.detail-container {
  flex-direction: column;

  .hex-container {
    flex-direction: column;
    gap: @base-size*2;

    .hex-no-data {
      justify-content: center;
      align-items: center;
    }

    .hex-vert-text.core,
    .hex-vert-text.nocore {
      position: absolute;
      writing-mode: vertical-rl;
      transform: rotate(-180deg);
      padding-right: (@base-size/2);
      font-size: xx-large;
      border-left: @border-size solid @color-border;
    }

    .hex-box {
      position: relative;
      // display: flex;
      margin: @base-size @base-size*3;
      padding-left: @base-size*2;

      div {
        flex: unset;
      }

      .hex-content::before {
        content: "";
        float: left;
        height: 120%;
      }

      .hex-content.core::before {
        width: (@hex-core-size/2) + @hex-core-margin;
        shape-outside: repeating-linear-gradient(#0000 0 (@hex-core-f - 3px), #000 0 @hex-core-f);
      }

      .hex-content.nocore::before {
        width: (@hex-nocore-size/2) + @hex-nocore-margin;
        shape-outside: repeating-linear-gradient(#0000 0 (@hex-nocore-f - 3px), #000 0 @hex-nocore-f);
      }

      .hex-content {
        padding: 0 0 50px;
        font-size: 0;
        display: block;
        overflow: hidden;

        .hex-border {
          clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
          -webkit-clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
          -ms-clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
          -moz-clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
          font-size: initial;
          display: inline-block;
          // position: relative;
          cursor: pointer;

          .hex-text {
            clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
            -webkit-clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
            -ms-clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
            -moz-clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
            background-color: @color-hex;
            // display: flex;
            // flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            gap: 0;

            &:hover {
              background-color: @color-hex-hover;
            }

            p {
              margin: 0;
            }

            .hex-cmdb {
              font-size: 80%;
              font-weight: 700;
              position: absolute;
              top: @base-size*2;
            }

            .hex-title {
              text-align: center;
              font-size: 90%;
              padding: 0 (@base-size/2);
              overflow-wrap: break-word;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              line-clamp: 3;
              -webkit-box-orient: vertical;
              -ms-word-break: break-all;
              word-break: break-all;
              word-break: break-word;
              -webkit-hyphens: auto;
              -moz-hyphens: auto;
              -ms-hyphens: auto;
              hyphens: auto;
            }

            .hex-opex {
              font-size: 80%;
              font-weight: 700;
              position: absolute;
              bottom: @base-size*2;
            }
          }

          .hex-active {
            background-color: @color-hex-active !important;

            &:hover {
              background-color: @color-hex-hover !important;
            }
          }
        }

        .hex-border.core {
          width: @hex-core-size;
          height: @hex-core-size*1.1547;
          margin: @hex-core-margin;
          margin-bottom: @hex-core-margin - @hex-core-size*0.2885;

          .hex-text {
            top: @hex-core-border;
            left: @hex-core-border;
            height: @hex-core-size*1.1547 - @hex-core-border*2;
            width: @hex-core-size - @hex-core-border*2;
          }
        }

        .hex-border.nocore {
          width: @hex-nocore-size;
          height: @hex-nocore-size*1.1547;
          margin: @hex-nocore-margin;
          margin-bottom: @hex-nocore-margin - @hex-nocore-size*0.2885;

          .hex-text {
            top: (@hex-nocore-border/2);
            left: (@hex-nocore-border/2);
            height: @hex-nocore-size*1.1547 - @hex-nocore-border;
            width: @hex-nocore-size - @hex-nocore-border;

            .hex-cmdb {
              font-size: 70%;
            }

            .hex-title {
              font-size: 80%;
            }

            .hex-opex {
              font-size: 70%;
            }
          }
        }
      }
    }

    /* .hex-separator {
      border-bottom: @border-size solid @color-border;
      flex: 0;
    } */
  }
}