@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./assets/fonts/Montserrat-VariableFont_wght.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat-italic';
  src: local('Montserrat-italic'), url(./assets/fonts/Montserrat-Italic-VariableFont_wght.ttf) format('truetype');
}
/* @color-obsolescence: #00a7d7;
@color-obsolescence-hover: #00A7D77f;
@color-obsolescence-box-hover: #00a8d7b7;
@color-coverage: #0085b4;
@color-coverage-hover: #0085B47f;
@color-coverage-box-hover: #0085B4b7;
@color-evolvability: #006591;
@color-evolvability-hover: #0065917f;
@color-evolvability-box-hover: #006591b7; */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%) translatey(5px) rotateY(16deg);
    transform-origin: 50% 50% 0;
  }
  100% {
    transform: translateX(0) translatey(5px) rotateY(16deg);
    transform-origin: 50% 50% 0;
  }
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%) translatey(5px) rotateY(-16deg);
    transform-origin: 50% 50% 0;
  }
  100% {
    transform: translateX(0) translatey(5px) rotateY(-16deg);
    transform-origin: 50% 50% 0;
  }
}
@keyframes floatLeft {
  0% {
    transform: translatey(5px) rotateY(16deg);
    transform-origin: 50% 50% 0;
  }
  50% {
    transform: translatey(-5px) rotateY(12deg);
    transform-origin: 50% 50% 0;
  }
  100% {
    transform: translatey(5px) rotateY(16deg);
    transform-origin: 50% 50% 0;
  }
}
@keyframes floatRight {
  0% {
    transform: translatey(5px) rotateY(-16deg);
    transform-origin: 50% 50% 0;
  }
  50% {
    transform: translatey(-5px) rotateY(-12deg);
    transform-origin: 50% 50% 0;
  }
  100% {
    transform: translatey(5px) rotateY(-16deg);
    transform-origin: 50% 50% 0;
  }
}
@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(0.95);
  }
}
html {
  height: 100vh;
  background: linear-gradient(0deg, #19384f 0%, #00192b 100%);
  background-attachment: fixed;
}
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  height: 100%;
}
* {
  color: #FFFFFF;
  text-transform: uppercase;
  font-variant-numeric: tabular-nums;
  transition: color 0.5s ease, background-color 0.5s ease, fill 0.5s ease, stroke 0.5s ease;
}
div {
  display: flex;
  flex: 1;
  gap: 16px;
  letter-spacing: 0.1rem;
}
.uom {
  font-size: 75%;
  opacity: 0.75;
}
.btn {
  background-color: #08596e;
  border: none;
  font-size: medium;
  cursor: pointer;
  height: 64px;
  flex: 1;
  border-radius: 2px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: 'Montserrat', sans-serif;
}
.btn svg {
  fill: #FFFFFF;
  width: 21.33333333px;
  height: 21.33333333px;
  padding-bottom: 5px;
}
.btn:not([disabled]):hover {
  background-color: #5EBAD0;
}
.btn[disabled] {
  background-color: #08596e7f;
  opacity: 0.5;
  cursor: unset;
}
.btn[disabled] svg {
  opacity: 0.5;
}
.sider-close-btn {
  flex: 0;
  position: absolute;
  top: -20px;
  z-index: 100;
}
.sider-close-btn svg {
  fill: #FFFFFF;
  width: 21.33333333px;
  height: 21.33333333px;
  cursor: pointer;
}
.capex-opex-section {
  flex: 0;
}
.capex-opex-section .capex-opex-box {
  flex-direction: column;
  align-items: center;
  gap: 4px;
}
.capex-opex-section .capex-opex-box .capex-opex-title {
  opacity: 0.75;
  font-weight: 500;
  font-size: 90%;
}
.capex-opex-section .capex-opex-box .capex-opex-value {
  gap: 3.2px;
  align-items: center;
}
.capex-opex-section .capex-opex-box .capex-opex-value .capex-opex-num {
  font-size: xx-large;
}
.total-core-box .total-core-title {
  opacity: 0.8;
  font-size: 80%;
  font-weight: 500;
  align-items: center;
}
.total-core-box .total-core-value {
  font-size: xx-large;
  justify-content: end;
}
.indicator-box {
  flex-direction: column;
  gap: 8px;
}
.indicator-box .indicator-info .indicator-name {
  opacity: 0.8;
  font-size: 80%;
  font-weight: 500;
}
.indicator-box .indicator-info .indicator-value {
  justify-content: end;
}
.indicator-box .progress-bar-container {
  flex-direction: column;
  gap: 1.6px;
}
.indicator-box .progress-bar-container .progress-bar-value .progress-bar-perc {
  justify-content: end;
  gap: 3.2px;
}
.indicator-box .progress-bar-container .progress-bar {
  position: relative;
}
.indicator-box .progress-bar-container .progress-bar .progress-bar-track {
  position: absolute;
  bottom: 1px;
  height: 2.66666667px;
  width: 100%;
  border-radius: 4px;
  background-color: #00688a;
  opacity: 0.5;
}
.indicator-box .progress-bar-container .progress-bar .progress-bar-range {
  border-radius: 4px;
  height: 5.33333333px;
  z-index: 100;
}
.indicator-box .bar-leading .progress-bar-range {
  background-color: #00E9A5;
  box-shadow: 0 0 2px 1px #00E9A5;
}
.indicator-box .bar-leading .progress-bar-perc,
.indicator-box .bar-leading .progress-bar-perc .uom {
  color: #00E9A5;
}
.indicator-box .bar-basic .progress-bar-range {
  background-color: #FBE829;
  box-shadow: 0 0 2px 1px #FBE829;
}
.indicator-box .bar-basic .progress-bar-perc,
.indicator-box .bar-basic .progress-bar-perc .uom {
  color: #FBE829;
}
.indicator-box .bar-lagging .progress-bar-range {
  background-color: #FF8C84;
  box-shadow: 0 0 2px 1px #FF8C84;
}
.indicator-box .bar-lagging .progress-bar-perc,
.indicator-box .bar-lagging .progress-bar-perc .uom {
  color: #FF8C84;
}
.wrapper {
  flex-direction: column;
  gap: 32px;
  padding-bottom: 48px;
  background-image: linear-gradient(#fefefe05 1px, transparent 2px, transparent 31px, #fefefe10 33px, transparent 2px), linear-gradient(to right, #fefefe05 1px, transparent 2px, transparent 31px, #fefefe10 33px, transparent 2px);
  background-size: 64px 64px;
  background-attachment: fixed;
  background-repeat: repeat;
  overflow: auto;
}
.wrapper .header {
  flex-basis: 112px;
  flex-grow: 0;
  flex-shrink: 0;
  gap: 0;
  filter: drop-shadow(0 0 8px #18859A);
  z-index: 1000;
}
.wrapper .header .logo-border {
  flex: 0 0 288px;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 32px*1.75), calc(100% - 32px*1.75) 100%, 0 100%);
  z-index: 100;
  background-color: #18859A;
}
.wrapper .header .logo-border .mds-logo {
  justify-content: center;
  align-items: center;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 32px*1.75 - 1px), calc(100% - 32px*1.75 - 1px) calc(100% - 1px*2), 0 calc(100% - 1px));
  background-color: #00253d;
}
.wrapper .header .logo-border .mds-logo svg {
  height: 56px;
  cursor: pointer;
  margin-right: 32px;
  fill: #FFFFFF;
  /* .m7 {
            fill: @color-text !important;
          } */
}
.wrapper .header .navbar {
  gap: 0;
  flex-direction: column;
}
.wrapper .header .navbar .nav {
  background-color: #00253d;
  z-index: 100;
  padding: 2px 0;
  border-bottom: 1px solid #18859A;
}
.wrapper .header .navbar .nav .breadcrumb {
  display: flex;
  align-items: center;
  margin: 0;
  gap: 8px;
}
.wrapper .header .navbar .nav .breadcrumb .li-click {
  cursor: pointer;
}
.wrapper .header .navbar .nav .breadcrumb .li-click:hover {
  text-decoration: underline;
}
.wrapper .header .navbar .nav .breadcrumb .li-click svg {
  margin-right: 5px;
}
.wrapper .header .navbar .nav .breadcrumb li {
  display: flex;
}
.wrapper .header .navbar .nav .breadcrumb li a {
  color: #FFFFFF;
  text-decoration: none;
  cursor: pointer;
}
.wrapper .header .navbar .nav .breadcrumb li a:hover {
  text-decoration: underline;
}
.wrapper .header .navbar .nav .breadcrumb li svg {
  fill: #FFFFFF;
  width: 21.33333333px;
  height: 21.33333333px;
}
.wrapper .header .navbar .nav .breadcrumb li + li:before {
  padding: 0 2px 0 0;
  color: #18859A;
  content: "/\00a0";
}
.wrapper .header .navbar .nav .breadcrumb li:last-of-type {
  font-weight: 700;
}
.wrapper .header .navbar .driver-filter {
  gap: 5px;
  background: #18859A;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 32px*1.75), calc(100% - 32px*1.75) 100%, 0 100%);
  overflow: hidden;
  padding-right: 2px;
  margin-right: 128px;
  margin-left: -50px;
  padding-left: 56px;
  border-bottom: 1px solid #18859A;
}
.wrapper .header .navbar .driver-filter .parallelogram {
  margin-left: -64px;
  clip-path: polygon(0 56px, 56px 0, 100% calc(100% - 32px*1.75), calc(100% - 32px*1.75) 100%, 0 100%);
  border-bottom: 4px solid transparent;
  border-top: 0;
  background-color: #08596e;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.wrapper .header .navbar .driver-filter .parallelogram:hover {
  background-color: #50D1D4;
}
.wrapper .header .navbar .driver-filter .parallelogram .parallelogram-content {
  flex: 0;
  gap: 8px;
}
.wrapper .header .navbar .driver-filter .parallelogram .parallelogram-content .parallelogram-icon svg {
  fill: #FFFFFF;
  width: 21.33333333px;
  height: 21.33333333px;
}
.wrapper .header .navbar .driver-filter .parallelogram.active {
  background-color: #18859A !important;
}
.wrapper .header .navbar .driver-filter .parallelogram.active:hover {
  background-color: #5EBAD0 !important;
}
.wrapper .header .navbar .driver-filter .parallelogram.obsolescence:hover {
  background-color: #00D0F0 !important;
}
.wrapper .header .navbar .driver-filter .parallelogram.obsolescence.active {
  background-color: #00AAE0 !important;
}
.wrapper .header .navbar .driver-filter .parallelogram.obsolescence.active:hover {
  background-color: #00C2DF !important;
}
.wrapper .header .navbar .driver-filter .parallelogram.coverage:hover {
  background-color: #7FA4D5 !important;
}
.wrapper .header .navbar .driver-filter .parallelogram.coverage.active {
  background-color: #897EBA !important;
}
.wrapper .header .navbar .driver-filter .parallelogram.coverage.active:hover {
  background-color: #8291CA !important;
}
.wrapper .header .navbar .driver-filter .parallelogram.evolvability:hover {
  background-color: #5EA2D2 !important;
}
.wrapper .header .navbar .driver-filter .parallelogram.evolvability.active {
  background-color: #005B95 !important;
}
.wrapper .header .navbar .driver-filter .parallelogram.evolvability.active:hover {
  background-color: #3983B1 !important;
}
.wrapper .header .navbar .driver-filter .parallelogram.active.leading,
.wrapper .header .navbar .driver-filter .parallelogram.obsolescence.active.leading,
.wrapper .header .navbar .driver-filter .parallelogram.coverage.active.leading,
.wrapper .header .navbar .driver-filter .parallelogram.evolvability.active.leading {
  border-bottom: 4px solid #00E9A5;
}
.wrapper .header .navbar .driver-filter .parallelogram.active.basic,
.wrapper .header .navbar .driver-filter .parallelogram.obsolescence.active.basic,
.wrapper .header .navbar .driver-filter .parallelogram.coverage.active.basic,
.wrapper .header .navbar .driver-filter .parallelogram.evolvability.active.basic {
  border-bottom: 4px solid #FBE829;
}
.wrapper .header .navbar .driver-filter .parallelogram.active.lagging,
.wrapper .header .navbar .driver-filter .parallelogram.obsolescence.active.lagging,
.wrapper .header .navbar .driver-filter .parallelogram.coverage.active.lagging,
.wrapper .header .navbar .driver-filter .parallelogram.evolvability.active.lagging {
  border-bottom: 4px solid #FF8C84;
}
.wrapper .header .navbar .spacer {
  flex: 0 0 4px;
}
.wrapper .header .navbar .submenu {
  position: absolute;
  top: 107px;
  display: none;
  cursor: pointer;
  flex: 0 0;
}
.wrapper .header .navbar .submenu .submenu-dropdown {
  gap: 0;
  justify-content: space-evenly;
  background-color: #08596e;
  border: 1px solid #18859A;
  flex-direction: column;
}
.wrapper .header .navbar .submenu .submenu-dropdown .menu-item {
  flex: 0;
  padding: 16px;
  border-bottom: 1px solid #18859A;
}
.wrapper .header .navbar .submenu .submenu-dropdown .menu-item:hover {
  background-color: #50D1D4;
}
.wrapper .header .navbar .submenu .submenu-dropdown .menu-item .item-icon {
  flex: 0;
}
.wrapper .header .navbar .submenu .submenu-dropdown .menu-item .item-icon svg {
  width: 21.33333333px;
  height: 21.33333333px;
}
.wrapper .header .navbar .submenu .submenu-dropdown .menu-item .item-icon svg .hex-icon {
  fill: #FFFFFF;
}
.wrapper .header .navbar .submenu .submenu-dropdown .menu-item.obsolescence:hover {
  background-color: #00D0F0;
}
.wrapper .header .navbar .submenu .submenu-dropdown .menu-item.coverage:hover {
  background-color: #7FA4D5;
}
.wrapper .header .navbar .submenu .submenu-dropdown .menu-item.evolvability:hover {
  background-color: #5EA2D2;
}
.wrapper .header .navbar .submenu .submenu-dropdown .menu-item.all .item-icon .hex-shape {
  fill: #00A7D7;
}
.wrapper .header .navbar .submenu .submenu-dropdown .menu-item.leading .item-icon .hex-shape {
  fill: #00E9A5;
}
.wrapper .header .navbar .submenu .submenu-dropdown .menu-item.basic .item-icon .hex-shape {
  fill: #FBE829;
}
.wrapper .header .navbar .submenu .submenu-dropdown .menu-item.basic .item-icon .hex-icon.basic-icon {
  transform: scaleY(0.4) translate(25px, 90px);
}
.wrapper .header .navbar .submenu .submenu-dropdown .menu-item.lagging .item-icon .hex-shape {
  fill: #FF8C84;
}
.content {
  gap: 32px;
  perspective: 3000px;
}
.content.hide {
  opacity: 50%;
  pointer-events: none;
}
.wrapper.login {
  justify-content: center;
}
.wrapper.login .login-content {
  flex-direction: column;
  align-items: center;
  gap: 0;
  flex: 0;
}
.wrapper.login .login-content .login-img {
  width: 320px;
  height: 320px;
  opacity: 0.25;
  padding-bottom: 64px;
}
.wrapper.login .login-content .login-img svg {
  fill: #FFFFFF;
}
.wrapper.login .login-content .login-form {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.wrapper.login .login-content .login-form .login-input {
  gap: 8px;
  flex-direction: column;
}
.wrapper.login .login-content .login-form .login-input p {
  margin: 0;
  text-align: center;
}
.wrapper.login .login-content .login-form .login-input input {
  border-radius: 2px;
  color: #000000;
  width: 320px;
  padding: 16px;
}
.wrapper.login .login-content .login-form .login-input .login-error-msg {
  color: red;
}
.wrapper.home .header .navbar .nav {
  padding: 0;
}
.wrapper.home .header .navbar .nav .nav-title {
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 125%;
}
.wrapper.home .header .navbar .driver-filter {
  background: transparent;
  border: 0;
}
.sider {
  flex-basis: 400px;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: #18859A;
  margin: 32px 0;
  transform-origin: 50% 50% 0;
}
.sider .sider-border {
  background-color: #00253d;
}
.sider .sider-border .sider-box {
  flex-direction: column;
  gap: 0;
}
.sider .sider-border .sider-box .sider-header-border {
  flex: 0 0 96px;
  filter: drop-shadow(0 0 8px #18859A);
  flex-direction: column;
}
.sider .sider-border .sider-box .sider-header-border .sider-header {
  background-color: #18859A;
}
.sider .sider-border .sider-box .sider-header-border .sider-header .sider-name {
  background-color: #19384f;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 125%;
  padding: 0 0 0 4px;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.sider .sider-border .sider-box .sider-content {
  flex-direction: column;
  padding: 32px 48px;
  position: relative;
}
.sider .sider-border .sider-box .sider-content .sider-footer {
  flex: 0 0 64px;
}
.sider .sider-border .sider-box .sider-content .sider-footer .footer-hint {
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgba(0, 167, 215, 0) 0%, rgba(0, 167, 215, 0.7) 30%, rgba(0, 167, 215, 0.7) 70%, rgba(0, 167, 215, 0) 100%);
  margin: 0 -32px;
}
.sider .sider-border .sider-box .sider-content::-webkit-scrollbar {
  width: 4px;
  border-radius: 4px;
}
.sider .sider-border .sider-box .sider-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.sider .sider-border .sider-box .sider-content::-webkit-scrollbar-thumb {
  background-color: #00688a;
  opacity: 0.5;
  border-radius: 4px;
}
.sider-left {
  flex-basis: 640px;
  overflow: hidden;
  transform: rotateY(32deg) translateX(-8px);
  animation: floatLeft 20s ease 1s infinite, slideInFromLeft 1s ease 0s 1;
  clip-path: polygon(0 0, calc(100% - 32px) 0, 100% 32px, 100% 100%, 0 100%);
}
.sider-left .sider-border {
  clip-path: polygon(0 1px, calc(100% - 32px) 1px, calc(100% - 1px) 33px, calc(100% - 1px) calc(100% - 1px), 0 calc(100% - 1px));
}
.sider-left .sider-border .sider-box .sider-header-border .sider-header {
  clip-path: polygon(0 0, calc(100% - 32px) 0, 100% 32px, 100% 100%, calc(32px*3) 100%, calc(32px*2) calc(100% - 32px), 0 calc(100% - 32px));
}
.sider-left .sider-border .sider-box .sider-header-border .sider-header .sider-name {
  clip-path: polygon(0 0, calc(100% - 32px) 0, 100% 32px, 100% calc(100% - 1px), calc(32px*3) calc(100% - 1px*2), calc(32px*2) calc(100% - 32px - 1px), 0 calc(100% - 32px - 1px*2));
}
.sider-left .sider-content {
  justify-content: space-evenly;
}
.sider-left .sider-content .sider-close-btn {
  left: 32px;
}
.sider-left .sider-content .map-sm {
  justify-content: center;
  align-items: center;
  flex: 0;
}
.sider-left .sider-content .map-sm svg {
  max-width: 384px;
  max-height: 224px;
}
.sider-left .sider-content .map-sm svg path {
  stroke: #18859A;
  stroke-dasharray: unset;
  stroke-width: 5px;
}
.sider-left .sider-content .map-sm svg path#italy-sm,
.sider-left .sider-content .map-sm svg path#spain-sm,
.sider-left .sider-content .map-sm svg path#border-sm {
  fill: #18859A;
  fill-opacity: 0.2;
}
.sider-left .sider-content .info-rows {
  flex-direction: column;
  gap: 48px;
  flex: 0;
  padding: 0 16px;
}
.sider-left .sider-content .info-rows .info-row {
  gap: 48px;
}
.sider-left .sider-content .info-rows .info-row .info-content {
  gap: 0;
}
.sider-left .sider-content .info-rows .info-row .info-content .info-icon-border {
  justify-content: center;
  align-items: center;
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
  flex: 0 0 65px;
  background-color: #18859A;
}
.sider-left .sider-content .info-rows .info-row .info-content .info-icon-border .info-icon {
  justify-content: center;
  align-items: center;
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
  height: 64px;
  width: 64px;
  background-color: #19384f;
  flex-basis: 64px;
  flex-grow: 0;
  flex-shrink: 0;
}
.sider-left .sider-content .info-rows .info-row .info-content .info-icon-border .info-icon svg {
  fill: #FFFFFF;
  width: 32px;
  height: 32px;
  opacity: 0.75;
}
.sider-left .sider-content .info-rows .info-row .info-content .info-box {
  flex-direction: column;
  gap: 0;
}
.sider-left .sider-content .info-rows .info-row .info-content .info-box .info-title {
  border-bottom: 0.5px solid #18859A;
  flex: 1 0 32px;
}
.sider-left .sider-content .info-rows .info-row .info-content .info-box .info-title div {
  justify-content: center;
  align-items: end;
  padding-bottom: 2.66666667px;
  font-weight: 700;
}
.sider-left .sider-content .info-rows .info-row .info-content .info-box .info-value {
  flex: 1 0 32.5px;
  font-weight: 300;
  gap: 3.2px;
  align-items: center;
  justify-content: center;
}
.sider-left .sider-content .info-rows .info-row .info-content .info-box .info-value div {
  padding-top: 2.66666667px;
  font-size: x-large;
  flex: 0;
}
.sider-left .sider-content .info-rows .info-row .info-content .info-box .info-value .uom {
  padding-top: 4px;
}
.sider-left .sider-content .info-rows .row-reverse .info-content {
  flex-direction: row-reverse;
}
.sider-right {
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 32px, 32px 0);
  transform: rotateY(-16deg) translateX(-8px);
  animation: floatRight 20s ease 1s infinite, slideInFromRight 1s ease 0s 1;
}
.sider-right .sider-border {
  border-right: 0;
  border-bottom: 1px solid #18859A;
}
.sider-right .sider-border .sider-box .sider-header-border .sider-header {
  padding: 1px 0 1px 1px;
  clip-path: polygon(100% 0, 100% calc(100% - 32px), calc(100% - 32px*2) calc(100% - 32px), calc(100% - 32px*3) 100%, 0 100%, 0 32px, 32px 0);
  flex: 0 0 96px;
}
.sider-right .sider-border .sider-box .sider-header-border .sider-header .sider-name {
  clip-path: polygon(100% 0, 100% calc(100% - 32px), calc(100% - 32px*2) calc(100% - 32px), calc(100% - 32px*3) 100%, 0 100%, 0 32px, 32px 0);
  padding: 0 96px 0 32px;
  text-align: center;
}
.sider-right .sider-border .sider-box .sider-content {
  border-left: 1px solid #18859A;
}
.sider-right .sider-border .sider-box .sider-content .sider-close-btn {
  right: 22px;
}
.sider-right .sider-border .sider-box .sider-content .sider-section {
  flex: 0;
  margin-top: 32px;
  border-bottom: 1px solid #18859A;
  padding-bottom: 4px;
}
.sider-right .sider-border .sider-box .sider-content .sider-section .section-title {
  justify-content: center;
  font-size: large;
}
.sider-right .sider-border .sider-box .sider-content .sider-section:first-of-type,
.sider-right .sider-border .sider-box .sider-content .sider-close-btn + .sider-section {
  margin-top: 0;
}
.sider-right .sider-border .sider-box .sider-content .systems {
  gap: 24px;
  flex: 0;
}
.sider-right .sider-border .sider-box .sider-content .systems .systems-column {
  flex-direction: column;
}
.sider-right .sider-border .sider-box .sider-content .systems .systems-column .driver-indicators {
  flex-direction: column;
  gap: 32px;
  padding: 16px 3.2px;
  /* .driver-info-box {
                flex: 0;
              } */
}
.sider-right .sider-border .sider-box .sider-content .detail-icons {
  flex: 0;
  gap: 4px;
}
.sider-right .sider-border .sider-box .sider-content .detail-icons .icon-box {
  flex-direction: column;
  align-items: center;
  gap: 4px;
}
.sider-right .sider-border .sider-box .sider-content .detail-icons .icon-box svg {
  width: 48px;
  height: 48px;
}
.sider-right .sider-border .sider-box .sider-content .detail-icons .icon-box svg .hex-icon {
  fill: #FFFFFF;
  transform: scale(0.8) translate(25px, 25px);
  transform-origin: center;
}
.sider-right .sider-border .sider-box .sider-content .detail-icons .icon-box svg .basic-icon {
  transform: scaleY(0.4) translate(25px, 25px);
}
.sider-right .sider-border .sider-box .sider-content .detail-icons .icon-box .icon-title {
  flex-direction: column;
  flex: 1 0 32.5px;
}
.sider-right .sider-border .sider-box .sider-content .detail-icons .icon-box .icon-title div {
  padding-top: 2.66666667px;
  font-size: 80%;
  font-weight: 500;
}
.sider-right .sider-border .sider-box .sider-content .detail-infos {
  flex: 0;
  flex-direction: column;
}
.sider-right .sider-border .sider-box .sider-content .detail-infos .sider-section.no-space {
  margin-top: 0 !important;
}
.sider-right .sider-border .sider-box .sider-content .detail-infos .detail-description {
  text-transform: initial;
  letter-spacing: 0;
}
.sider-right .sider-border .sider-box .sider-content .detail-infos .detail-title {
  padding: 0 8px;
  font-size: 80%;
  font-weight: 600;
  color: #18859A;
}
.sider-right .sider-border .sider-box .sider-content .detail-infos p.detail-title {
  text-align: center;
}
.sider-right .sider-border .sider-box .sider-content .detail-infos .initiative-box {
  flex-direction: column;
  gap: 8px;
  border: 1px solid #18859A;
  padding: 32px 32px;
  transform-style: preserve-3d;
  transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);
}
.sider-right .sider-border .sider-box .sider-content .detail-infos .initiative-box .initiative-content {
  flex-direction: column;
  transform: translateZ(20px);
}
.sider-right .sider-border .sider-box .sider-content .detail-infos .initiative-box .initiative-content .initiative-title {
  text-align: center;
  margin: 0;
}
.sider-right .sider-border .sider-box .sider-content .detail-infos .initiative-box .initiative-content .initiative-row {
  justify-content: center;
}
.sider-right .sider-border .sider-box .sider-content .detail-infos .initiative-box .initiative-content .initiative-row .initiative-date {
  margin: 0;
  opacity: 0.5;
  font-size: small;
  margin-bottom: 32px;
}
.sider-right .sider-border .sider-box .sider-content .detail-infos .initiative-box .initiative-content .initiative-row .detail-info {
  flex-direction: column;
  align-items: center;
}
.sider-right .sider-border .sider-box .sider-content .detail-infos .detail-info .detail-value {
  gap: 8px;
}
.sider-right .sider-border .sider-box .sider-content .detail-infos .detail-info .detail-value .uom {
  display: flex;
  align-items: center;
}
.sider-detail {
  position: sticky;
  top: 5%;
  height: calc(100vh - 16px*7);
  perspective: 3000px;
  flex-basis: 400px;
  flex-grow: 0;
  flex-shrink: 0;
}
.sider-detail .sider-border .sider-box .sider-close-btn {
  right: 22px;
  top: 78px;
}
.sider-detail .sider-border .sider-box .sider-content {
  margin-right: 8px;
  padding: 32px;
  gap: 32px;
  overflow-y: auto;
}
.map-lg {
  align-items: center;
  justify-content: center;
  animation: 1s ease-in-out 0s 1 zoomIn;
}
.map-lg svg {
  max-height: calc(100vh - 16px*20);
}
.map-lg svg .country {
  stroke: #18859A;
  stroke-width: 1px;
  stroke-opacity: 0.25;
  fill: #003e5370;
  fill-opacity: 0.5;
}
.map-lg svg .country:hover {
  fill-opacity: 1;
}
.map-lg svg #border {
  stroke: #18859A;
  stroke-width: 2px;
  fill: none;
}
.map-lg svg .pin {
  fill: #00A7D7;
  stroke: #00A7D7;
}
.map-lg svg .pin-line {
  stroke: #00A7D7;
}
.map-lg #italy,
.map-lg #spain {
  fill: #18859A;
  fill-opacity: 0.25;
  stroke-opacity: 1;
}
.map-lg #it:hover .country,
.map-lg #es:hover .country {
  fill: #18859A !important;
  fill-opacity: 0.5;
  stroke-opacity: 1;
}
.map-lg #it.selected .country,
.map-lg #es.selected .country {
  fill: #18859A !important;
  fill-opacity: 0.75;
  stroke-opacity: 1;
  stroke-width: 2px;
}
.map-lg #it.selected .pin,
.map-lg #es.selected .pin {
  fill: #FFFFFF !important;
  stroke: #FFFFFF !important;
}
.map-lg #it.selected .pin-line,
.map-lg #es.selected .pin-line {
  stroke: #FFFFFF !important;
}
.map-lg #cologno,
.map-lg #elios,
.map-lg #palatino,
.map-lg #villaviciosa,
.map-lg #fuencarral {
  letter-spacing: 0;
}
.map-lg .pinned {
  transform: scale(1.25);
  transform-box: fill-box;
}
.map-lg .pinned-sx {
  transform-origin: bottom left;
}
.map-lg .pinned-dx {
  transform-origin: bottom right;
}
.cap-map {
  animation: zoomIn 1s ease 0s 1;
  transform: scale(0.95);
  flex-direction: column;
  margin-left: 32px;
  gap: 24px;
}
.cap-map .cap-center {
  flex: 4;
  gap: 24px;
}
.cap-map .cap-center .cap-med-center {
  flex-direction: column;
  flex: 4;
  gap: 24px;
}
.cap-map .cap-center .cap-med-center .cap-super-center {
  gap: 24px;
}
.cap-map .capability {
  background-color: #003e5370;
  cursor: pointer;
}
.cap-map .capability .capability-content {
  background-color: #003e5370;
}
.cap-map .capability .capability-content:hover {
  background-color: #50D1D4;
}
.cap-map .capability .capability-content .cap-name {
  font-size: 105%;
  font-weight: 500;
  text-align: center;
}
.cap-map .capability .capability-content .cap-info-container {
  align-items: center;
}
.cap-map .capability .capability-content .cap-info-container .cap-info span {
  display: flex;
  flex-direction: column;
}
.cap-map .capability .capability-content .cap-info-container .cap-info p {
  margin: 0;
}
.cap-map .capability .capability-content .cap-info-container .cap-info .title {
  font-weight: 600;
  font-size: 50%;
  opacity: 0.5;
  display: flex;
  justify-content: center;
}
.cap-map .capability .capability-content .cap-info-container .cap-info .value {
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
}
.cap-map .capability .capability-content .cap-info-container .cap-info .value .indicator-icon svg {
  width: 24px;
  height: 24px;
}
.cap-map .capability .capability-content .cap-info-container .cap-info .value .indicator-icon .hex-icon {
  fill: #FFFFFF;
}
.cap-map .capability .capability-content .cap-info-container .cap-info .value .indicator-icon.icon-leading .hex-shape {
  fill: #00E9A5;
}
.cap-map .capability .capability-content .cap-info-container .cap-info .value .indicator-icon.icon-basic .hex-shape {
  fill: #FBE829;
}
.cap-map .capability .capability-content .cap-info-container .cap-info .value .indicator-icon.icon-basic .hex-icon.basic-icon {
  transform: scaleY(0.4) translate(25px, 90px);
}
.cap-map .capability .capability-content .cap-info-container .cap-info .value .indicator-icon.icon-lagging .hex-shape {
  fill: #FF8C84;
}
.cap-map .capability .capability-content .cap-info-container .cap-info .total-num {
  padding: 5px;
}
.cap-map .capability .capability-content .cap-info-container .cap-info .total-num .value {
  opacity: 0.75;
}
.cap-map .capability .capability-content .cap-info-container .cap-info .core-box {
  border: 1px solid #ffffff37;
  padding: 4px 16px;
}
.cap-map .capability .capability-content .cap-info-container .cap-info .core-box .core-num {
  font-weight: 500;
}
.cap-map .capability .capability-content .cap-info-container .cap-info .core-box .core-num .value {
  font-size: 150%;
}
.cap-map .capability .capability-content .cap-info-container .cap-info .core-box .core-perc .value {
  gap: 4px;
}
.cap-map .capability .capability-content .cap-info-container .cap-info .total-num,
.cap-map .capability .capability-content .cap-info-container .cap-info .core-num,
.cap-map .capability .capability-content .cap-info-container .cap-info .core-perc {
  gap: 4px;
}
.cap-map .capability-content.active {
  background-color: #18859A !important;
}
.cap-map .capability-content.active:hover {
  background-color: #5EBAD0 !important;
}
.cap-map .capability-hor-lg {
  position: relative;
  flex: 0.5;
  align-items: stretch;
  overflow: hidden;
  clip-path: polygon(0 50%, 32px 0, calc(100% - 32px) 0, 100% 50%, calc(100% - 32px) 100%, 32px 100%);
  border-top: 2px solid #18859A;
}
.cap-map .capability-hor-lg .capability-content-hor-lg {
  justify-content: center;
  gap: 96px;
}
.cap-map .capability-hor-lg .capability-content-hor-lg .cap-name {
  align-items: center;
  flex: 0 0 fit-content;
}
.cap-map .capability-hor-lg .capability-content-hor-lg .cap-info-container {
  flex: 0;
}
.cap-map .capability-hor-md,
.cap-map .capability-hor-sm {
  position: relative;
  clip-path: polygon(0 16px, 16px 0, 100% 0, 100% calc(100% - (32px/2)), calc(100% - (32px/2)) 100%, 0 100%);
  border-top: 2px solid #18859A;
}
.cap-map .capability-hor-md .capability-content-hor-md,
.cap-map .capability-hor-sm .capability-content-hor-md,
.cap-map .capability-hor-md .capability-content-hor-sm,
.cap-map .capability-hor-sm .capability-content-hor-sm {
  justify-content: center;
  flex-direction: column;
}
.cap-map .capability-hor-md .capability-content-hor-md .cap-name,
.cap-map .capability-hor-sm .capability-content-hor-md .cap-name,
.cap-map .capability-hor-md .capability-content-hor-sm .cap-name,
.cap-map .capability-hor-sm .capability-content-hor-sm .cap-name {
  flex: 0;
  justify-content: center;
}
.cap-map .capability-hor-md .capability-content-hor-md .cap-info-container,
.cap-map .capability-hor-sm .capability-content-hor-md .cap-info-container,
.cap-map .capability-hor-md .capability-content-hor-sm .cap-info-container,
.cap-map .capability-hor-sm .capability-content-hor-sm .cap-info-container {
  flex: 0;
  justify-content: center;
}
.cap-map .capability-hor-md .capability-content-hor-md .cap-info-container .cap-info,
.cap-map .capability-hor-sm .capability-content-hor-md .cap-info-container .cap-info,
.cap-map .capability-hor-md .capability-content-hor-sm .cap-info-container .cap-info,
.cap-map .capability-hor-sm .capability-content-hor-sm .cap-info-container .cap-info {
  flex: 0;
}
.cap-map .capability-vert {
  position: relative;
  clip-path: polygon(0 0, calc(100% - 32px) 0, 100% 32px, 100% 100%, 32px 100%, 0 calc(100% - 32px));
  overflow: hidden;
  border-left: 2px solid #18859A;
}
.cap-map .capability-vert .capability-content-vert {
  align-items: center;
}
.cap-map .capability-vert .capability-content-vert .cap-name {
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  padding-right: 8px;
  flex: 0;
}
.cap-map .capability-vert .capability-content-vert .cap-info-container {
  justify-content: center;
  flex: 1;
}
.cap-map .capability-vert .capability-content-vert .cap-info-container .cap-info {
  flex-direction: column;
  flex: 0;
  /* .total-num {
            padding: 0;
            padding-bottom: @base-size;
            border: 0;
            border-bottom: @border-size solid #ffffff37;
          } */
}
.cap-map .capability-vert .capability-content-vert .cap-info-container .cap-info .core-box {
  flex-direction: column;
}
.cap-map .capability-hor-lg::after,
.cap-map .capability-hor-md::after,
.cap-map .capability-hor-sm::after {
  content: "";
  filter: drop-shadow(0 0 8px #18859A);
  width: 100%;
  position: absolute;
  height: 10px;
  background: #18859A;
  margin-top: -10px;
}
.cap-map .capability-vert::after {
  content: "";
  filter: drop-shadow(0 0 8px #18859A);
  height: 100%;
  position: absolute;
  width: 10px;
  background: #18859A;
  margin-left: -10px;
}
.cap-map.obsolescence .capability {
  border-color: #00AAE0 !important;
}
.cap-map.obsolescence .capability .capability-content:hover {
  background-color: #00D0F0 !important;
}
.cap-map.obsolescence .capability .capability-content.active {
  background-color: #00AAE0 !important;
}
.cap-map.obsolescence .capability .capability-content.active:hover {
  background-color: #00C2DF !important;
}
.cap-map.obsolescence .capability::after {
  background-color: #00AAE0;
  filter: drop-shadow(0 0 8px #00AAE0);
}
.cap-map.coverage .capability {
  border-color: #897EBA !important;
}
.cap-map.coverage .capability .capability-content:hover {
  background-color: #7FA4D5 !important;
}
.cap-map.coverage .capability .capability-content.active {
  background-color: #897EBA !important;
}
.cap-map.coverage .capability .capability-content.active:hover {
  background-color: #8291CA !important;
}
.cap-map.coverage .capability::after {
  background-color: #897EBA;
  filter: drop-shadow(0 0 8px #897EBA);
}
.cap-map.evolvability .capability {
  border-color: #005B95 !important;
}
.cap-map.evolvability .capability .capability-content:hover {
  background-color: #5EA2D2 !important;
}
.cap-map.evolvability .capability .capability-content.active {
  background-color: #005B95 !important;
}
.cap-map.evolvability .capability .capability-content.active:hover {
  background-color: #3983B1 !important;
}
.cap-map.evolvability .capability::after {
  background-color: #005B95;
  filter: drop-shadow(0 0 8px #005B95);
}
.detail-banner {
  padding: 208px 32px 32px 32px;
  border-bottom: 1px solid #18859A;
  filter: drop-shadow(0 0 8px #18859A);
  background-color: #00253d;
  flex: 0 0 96px;
  margin-top: -208px;
}
.detail-banner .banner-box {
  flex-direction: column;
  gap: 32px;
}
.detail-banner .banner-box .banner-row {
  flex: 0 0 64px;
  gap: 96px;
}
.detail-banner .banner-box .banner-row .banner-info-box {
  align-items: center;
  justify-content: space-evenly;
  flex: 0 0 192px;
}
.detail-banner .banner-box .banner-row .banner-info-box .banner-icon {
  flex: 0;
  justify-content: center;
}
.detail-banner .banner-box .banner-row .banner-info-box .banner-icon svg {
  width: 32px;
  height: 32px;
  fill: #FFFFFF;
}
.detail-banner .banner-box .banner-row .banner-info-box .total-core-box .total-core-title {
  opacity: unset;
  font-size: initial;
  font-weight: initial;
}
.detail-banner .banner-box .banner-row .banner-info-box .total-core-box .total-core-value {
  align-items: center;
}
.detail-banner .banner-box .banner-row .indicator-box .banner-indicator .banner-icon {
  flex: 0;
}
.detail-banner .banner-box .banner-row .indicator-box .banner-indicator .banner-icon svg {
  width: 21.33333333px;
  height: 21.33333333px;
  fill: #FFFFFF;
}
.detail-banner .banner-box .banner-row .indicator-box .banner-indicator .banner-icon svg .basic-icon {
  transform: scaleY(0.2) translate(10px, 90px);
}
.detail-banner .banner-box .banner-row:first-of-type {
  padding-bottom: 32px;
}
.detail-banner .indicator-info {
  flex: 0;
  gap: 8px;
  width: fit-content;
}
.detail-banner .indicator-info .indicator-name {
  opacity: unset !important;
  font-size: initial !important;
}
.detail-banner .bar-leading,
.detail-banner .bar-basic,
.detail-banner .bar-lagging {
  flex: 0;
}
.detail-banner.hide {
  opacity: 50%;
  pointer-events: none;
}
.detail-container {
  flex-direction: column;
}
.detail-container .hex-container {
  flex-direction: column;
  gap: 32px;
  /* .hex-separator {
      border-bottom: @border-size solid @color-border;
      flex: 0;
    } */
}
.detail-container .hex-container .hex-no-data {
  justify-content: center;
  align-items: center;
}
.detail-container .hex-container .hex-vert-text.core,
.detail-container .hex-container .hex-vert-text.nocore {
  position: absolute;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  padding-right: 8px;
  font-size: xx-large;
  border-left: 1px solid #18859A;
}
.detail-container .hex-container .hex-box {
  position: relative;
  margin: 16px 48px;
  padding-left: 32px;
}
.detail-container .hex-container .hex-box div {
  flex: unset;
}
.detail-container .hex-container .hex-box .hex-content::before {
  content: "";
  float: left;
  height: 120%;
}
.detail-container .hex-container .hex-box .hex-content.core::before {
  width: 88px;
  shape-outside: repeating-linear-gradient(#0000 0 305.12px, #000 0 308.12px);
}
.detail-container .hex-container .hex-box .hex-content.nocore::before {
  width: 69.33333333px;
  shape-outside: repeating-linear-gradient(#0000 0 239.02933333px, #000 0 242.02933333px);
}
.detail-container .hex-container .hex-box .hex-content {
  padding: 0 0 50px;
  font-size: 0;
  display: block;
  overflow: hidden;
}
.detail-container .hex-container .hex-box .hex-content .hex-border {
  clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
  -webkit-clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
  -ms-clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
  -moz-clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
  font-size: initial;
  display: inline-block;
  cursor: pointer;
}
.detail-container .hex-container .hex-box .hex-content .hex-border .hex-text {
  clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
  -webkit-clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
  -ms-clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
  -moz-clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
  background-color: #003E53;
  align-items: center;
  justify-content: center;
  position: absolute;
  gap: 0;
}
.detail-container .hex-container .hex-box .hex-content .hex-border .hex-text:hover {
  background-color: #0C667D;
}
.detail-container .hex-container .hex-box .hex-content .hex-border .hex-text p {
  margin: 0;
}
.detail-container .hex-container .hex-box .hex-content .hex-border .hex-text .hex-cmdb {
  font-size: 80%;
  font-weight: 700;
  position: absolute;
  top: 32px;
}
.detail-container .hex-container .hex-box .hex-content .hex-border .hex-text .hex-title {
  text-align: center;
  font-size: 90%;
  padding: 0 8px;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.detail-container .hex-container .hex-box .hex-content .hex-border .hex-text .hex-opex {
  font-size: 80%;
  font-weight: 700;
  position: absolute;
  bottom: 32px;
}
.detail-container .hex-container .hex-box .hex-content .hex-border .hex-active {
  background-color: #0f829e !important;
}
.detail-container .hex-container .hex-box .hex-content .hex-border .hex-active:hover {
  background-color: #0C667D !important;
}
.detail-container .hex-container .hex-box .hex-content .hex-border.core {
  width: 160px;
  height: 184.752px;
  margin: 8px;
  margin-bottom: -38.16px;
}
.detail-container .hex-container .hex-box .hex-content .hex-border.core .hex-text {
  top: 3px;
  left: 3px;
  height: 178.752px;
  width: 154px;
}
.detail-container .hex-container .hex-box .hex-content .hex-border.nocore {
  width: 128px;
  height: 147.8016px;
  margin: 5.33333333px;
  margin-bottom: -31.59466667px;
}
.detail-container .hex-container .hex-box .hex-content .hex-border.nocore .hex-text {
  top: 2px;
  left: 2px;
  height: 143.8016px;
  width: 124px;
}
.detail-container .hex-container .hex-box .hex-content .hex-border.nocore .hex-text .hex-cmdb {
  font-size: 70%;
}
.detail-container .hex-container .hex-box .hex-content .hex-border.nocore .hex-text .hex-title {
  font-size: 80%;
}
.detail-container .hex-container .hex-box .hex-content .hex-border.nocore .hex-text .hex-opex {
  font-size: 70%;
}
